<template>
  <div :class="`bg-white d-flex ${isWide ? 'justify-content-between p-30' : 'flex-column p-10'}`">
    <div :class="`d-flex flex-column ${isWide ? 'w-50 px-20' : 'w-100'}`">
      <span :class="`font-bold ${isMobile ? 'font-20 mt-10' : 'font-30 mt-20'}`">EVENTBOX {{ $t('support') }}</span>
      <span :class="isMobile ? 'mt-5 font-15' : 'mt-10 font-20'">{{ $t('support_description')}}</span>
      <div class="mt-10 d-flex justify-content-between">
        <div class="w-50 d-flex flex-column">
          <span class="mt-5 mr-5 font-12">First name</span>
          <input class="w-100 input-normal" v-model="firstName" />
        </div>
        <div class="w-50 ml-5 d-flex flex-column">
          <span class="mt-5 font-12">Last name</span>
          <input class="w-100 input-normal" v-model="lastName" />
        </div>
      </div>
      <span class="mt-5 font-12">{{ $t('your_email') }}</span>
      <input class="w-100 input-normal" v-model="email" />
      <span class="mt-5 font-12">Question</span>
      <textarea class="w-100 input-normal" v-model="question" rows="5" maxlength="500" />
      <v-btn class="mt-10 w-100 button-normal" @click="onSubmit()" :disabled="!captchaVerified || !firstName || !lastName || !email || !question">
        <span class="px-10">{{ $t('submit') }}</span>
      </v-btn>
      <VueRecaptcha
        class="my-5 mx-auto"
        :sitekey="sitekey"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
        @error="onCaptchaError"
        />
    </div>
    <div class="w-50 px-20" v-if="isWide">
      <img class="w-100" :src="assets.contact_us_img" />
    </div>
  </div>
</template>

<script>
import contact_us_img from '@/assets/images/contact_us_img.png';

import { VueRecaptcha } from 'vue-recaptcha';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../../main';
import { showLoading } from '../../../functions';

export default {
  name: 'ContactUs',
  components: {
    VueRecaptcha
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  },
  data() {
    return {
      assets: {
        contact_us_img
      },
      sitekey: '6LdQ0gokAAAAAOAMjcU-uTYNhXSTCjJMVl6Sg4pF',
      //secretKey: '6LdQ0gokAAAAABbhIaoCcekkI_bw1OmlMfOAkRKm',
      captchaVerified: false,
      valid: false,
      firstName: '',
      lastName: '',
      question: '',
      email: ''
    };
  },
  methods: {
    onSubmit() {
      const from = `${this.firstName} ${this.lastName}, ${this.email}`;
      const params = {
        isCustom: true,
        from: from,
        question: this.question.replaceAll('\n', '<br/>'),
        to: 'info@eventboxde.com'
			};
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'sendVerificationCode');
			func(JSON.stringify(params)).then(response => {
				loader.hide();
        this.$toast.success(this.$t('support_email_sent'));
        this.question = '';
        this.email = '';
			}).catch(error => {
				loader.hide();
        this.$toast.error(this.$t('support_email_sent_failed') + '\n' + error.code + ', ' + error.message);
			});
    },
    onCaptchaVerified() {
      this.captchaVerified = true;
    },
    onCaptchaExpired() {
      this.captchaVerified = false;
    },
    onCaptchaError() {
      this.captchaVerified = false;
    }
  }
};
</script>
